.custom-file-input {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 20px;
}

.fake-input {
  display: flex;
  cursor: pointer;
  border: 1px solid;
  border-radius: 0.25rem;
  margin-top: 5px;
  padding: 7px 16px;
  outline: 0;
  text-align: left;
  font: inherit;
  font-size: 13px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .06);
  color: #636c72;
  border-color: #e2e8f0;
  background-color: white;
  max-width: 279px;

  @media only screen and (max-width: 1024px) {
    max-width: calc(100vw - 113px);
  }
  
  span {
    flex-grow: 1;
    margin-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.fake-input button {
  padding: 8px 16px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.hidden-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
