.reports {
  /* Reset some default styles */
  .wrapper-report {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 20px;
  }

  .split-report {
    width: 50%;
    float: left;
    padding: 0;
  }

  .wrapper-select {
    position: relative;
    margin-bottom: 20px;
    max-width: 224px;
    margin: 0 auto;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  li {
    display: inline-block;
    margin-right: 10px;
    
    button {
      padding: 10px 15px;
      font-size: 1rem;
      background-color: #fff;
      border: 1px solid #ddd;
      cursor: pointer;
      transition: background-color 0.3s, color 0.3s;

      &.active {
        background-color: #000;
        color: #fff;
        border: 1px solid #000;
      }
    }
  }

  .table-container table thead tr:first-child {
    display: contents;
  }

  select {
    width: 200px;
    margin: 20px 20px;
  }

  .loader {
    margin-top: 50px;
  }
  
  .wrapper-date-filter {
    .pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0px auto 20px;
      position: static;
    }
  }

  .wrapper-inputs-rt {
    position: relative;
    input[type='checkbox'] {
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.report-ledger {
  .table-container table thead th, .table-container table tfoot {
    position: static;
  }
}

.red {
  color: red;
}

.blue {
  color:darkblue;
}