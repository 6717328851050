.forgot-password-success-screen {
  background-color: #f2f2f2;

  .login-box__subheader {
    margin-bottom: 3.6rem;
  }

  &__link {
    display: block;
    color: #ffffff;
    margin-top: 2.4rem;
    text-align: center;
    font-size: 1.4rem;
    line-height: 1.7rem;
    font-weight: 700;
    text-decoration: none;
  }
}
