.companies-screen {
  position: relative;
  label {
    text-align: left;
    font-size: 12px;
    display: block;

    &:nth-child(1) {
      width: 91%;
    }

    &:nth-child(2) {
      width: 9%;
      text-align: center;
    }

    &:nth-child(3) {
      width: 100%;
    }
  }

  &__form {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  
  .btn--primary {
    text-align: center;
  }

  .editSup {
    position: absolute;
    background: none;
    border: 0;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }

  h2 {
    margin-top: 0;
  }
}