.supplier-screen {
  position: relative;
  label {
    text-align: left;
    font-size: 12px;
    display: block;
  }
  &__form {
    width: 100%;
    margin: 0 auto;
  }
  .btn--primary {
    text-align: center;
  }

  .editSup {
    position: absolute;
    background: none;
    border: 0;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }

  h2 {
    margin-top: 0;
  }
}