// @import '../../styles/variables.scss';

.header {
  display: flex;
  background-color: #fff;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #ccc;
  box-shadow: 0 2px 10px 0 rgba(94,86,105,.1);
  > div {
    height: 100%;
    padding: 10px;
    align-items: center;
  }
  ul {
    display: flex;
    padding: 0;
    margin: 0;
    li {
      margin: 10px;
      list-style: none;
      align-items: center;
      a,
      span {
        display: block;
        position: relative;
        color: #333;
        font-size: 0.8rem;
        font-weight: 700;
        text-align: center;
        text-decoration: none;
        // padding-bottom: 10px;
        border-bottom: 2px solid transparent;
        cursor: pointer;
        &:hover {
          border-color: #333;
        }
      }
    }
  }
  &__tabs-container {
    flex: 0 1 57%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 5px;
  }

  &__user-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__username {
    color: #000;
    font-size: 1.8rem;
    line-height: 2.1rem;
    margin-left: 2rem;
  }
  

  &__logout {
    color: #333;
    font-size: 1rem;
    line-height: 2.1rem;
    text-decoration: none;
    cursor: pointer;
  }
  &__logo {
    width: auto;
    height: 100%;
  }

}

.exit {
  align-self: flex-end;
}

/* Dropdown Button */
.dropbtn {
  list-style: none;
  align-items: center;

  display: block;
        position: relative;
        color: #333;
        font-size: 0.8rem;
        font-weight: 700;
        text-align: center;
        text-decoration: none;
        // padding-bottom: 10px;
        border-bottom: 2px solid transparent;
        cursor: pointer;
        &:hover {
          border-color: #333;
        }
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  // display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  left: -50px;
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 8px 13px;
  text-decoration: none;
  display: block;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: block;}



.selected {
  border-bottom: 2px solid !important;
  border-color: #333;
}

@media only screen and (max-width: 1024px) {
  .header {
    align-items: normal;
    height: 60px;

    &__logo {
      position: absolute;
      width: 101px;
      height: auto;
      top: 0;
      left: 0px;
    }

    &__tabs-container {
      width: 100%;
      justify-content: end;
      flex: none;
      align-items: normal;
      padding: 0;

      ul {
        max-width: 65vw;
        position: relative;
        top: -10px;
        display: flex;
        align-items: center;
      }

      li {
        display: none;
        padding: 0 0px;

        &:last-child, &:first-child, &:nth-child(2) {
          display: block;
          // position: absolute;
          // right: 30px;
          // top: 12px;
        }

        a {
          font-size: 12px !important;
        }
      }
    }
  
    &__buttons-container,
    &__username {
      display: none;
    }    

    &__user-container {
      position: absolute;
      top: 24px;
      right: 61px;
    }

    &__logout {
      position: absolute;
      top: 29px;
      right: 24px;
    }
  }
}
@media print{
  .wrapper-select {
    display: none;
  }
  .no-print{
    display: none;
  }
}
