.login-screen {
  display: block;
  background-color: #fff;
  width: 90vw;
  max-width: 350px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  box-shadow: 0px 6px 10px rgba(0,0,0,.15);
  color: #fff;
  
  img {
    width: 100%;
    display: block;
    border-radius: 10px;
    margin-bottom: 20px;
  }

  a {
    color: #000000;
    display: block;
    text-decoration: none;
  }
}