.modal-companies {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0%;
  left: 0%;
  background: rgba(0,0,0,0.5);
  z-index: 1;

  .inner-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90vw;
    max-width: 400px;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    .wrapper-delete {
      button {
        width: 45%;
        margin: 2.5%
      }
    }
  }

  .btn-close {
    position: absolute;
    top: 10px;
    right: 10px;
    border: 0;
    background: #000;
    border-radius: 50%;
    color: #fff;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
}